// src/ServiceProviderLinks.js
import React from 'react';
import './ServiceProviderLinks.css';
import eProp from './assets/eProp.png';
import jetlineLogo from './assets/jetline-logo.png';
import baseLogo from './assets/base-logo.png';
import fusionLogo from './assets/fusion-logo.png';
import agentIqLogo from './assets/agent-iq-logo.png';
import virtualAgentLogo from './assets/virtual-agent-logo.png';

const serviceProviders = [
  { id: 1, text: "Website", url: "https://www.eproperty.net/", image: eProp },
  { id: 2, text: "Order marketing material", url: "https://bmp.jetline.co.za/", image: jetlineLogo },
  { id: 3, text: "Listings, marketing, and library", url: "https://base.entegral.net/", image: baseLogo },
  { id: 4, text: "Track your sale", url: "https://za.fusionagency.net/", image: fusionLogo },
  { id: 5, text: "Comparative Market Analysis", url: "https://www.agentiq.co.za/", image: agentIqLogo },
  { id: 6, text: "The Virtual Agent", url: "https://app.thevirtualagent.co.za/user/sign-in", image: virtualAgentLogo },
];

const ServiceProviderLinks = () => {
  return (
    <div className="container">
      <h1>Service Provider Links</h1>
      <hr className="red-hr" />
      <div className="grid">
        {serviceProviders.map(provider => (
          <a href={provider.url} key={provider.id} className="grid-item" target="_blank" rel="noopener noreferrer">
            <div className="content">
              <p>{provider.text}</p>
              <img src={provider.image} alt={provider.text} />
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default ServiceProviderLinks;