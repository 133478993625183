import React, { useState } from 'react';
import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import AWS from 'aws-sdk';
import ServiceProviderLinks from './ServiceProviderLinks';
import './ServiceProviderLinks.css'; // Import the CSS for styling
import './App.css'; // Import the CSS for styling the sign-out button and logo
import logo from './assets/eProp.png';


const poolData = {
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID, // Replace with your User Pool ID
  ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID // Replace with your App Client ID
};

const userPool = new CognitoUserPool(poolData);

const Header = ({ signOut }) => {
  return (
    <header className="app-header">
      <img
        alt="eProperty logo"
        src={logo}
        className="logo"
      />
      <button onClick={signOut} className="signout-button">Sign out</button>
    </header>
  );
};

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleSignIn = (username, password) => {
    const authenticationData = {
      Username: username,
      Password: password
    };

    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const userData = {
      Username: username,
      Pool: userPool
    };

    const cognitoUser = new CognitoUser(userData);

    
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function (result) {
        const accessToken = result.getAccessToken().getJwtToken();
        setIsAuthenticated(true);

        // Configure AWS SDK with the access token
        AWS.config.region = 'eu-west-1'; // Replace with your region
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID, // Replace with your Identity Pool ID
          Logins: {
            [`cognito-idp.eu-west-1.amazonaws.com/${poolData.UserPoolId}`]: result.getIdToken().getJwtToken()
          }
        });
      },
      onFailure: function (err) {
        alert(err.message || JSON.stringify(err));
      }
    });
  };

  const handleSignOut = () => {
    setIsAuthenticated(false);
  };

  const SignIn = ({ onSignIn }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (event) => {
      event.preventDefault();
      onSignIn(username, password);
    };

    return (
      <div className="signin-background centered-container">
        <img
          alt="eProperty logo"
          src={logo}
          className="logo"
        />
        <h3 className="headingBorder">Account Login</h3>
        <br/>
        <form className="signin-form" onSubmit={handleSubmit}>
        <label className="signin-label">
            Username:
            <br/>
            <input className="signin-input" type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Enter your username" required />
          </label>
          <br />
          <label className="signin-label">
            Password:
            <br/>
              <input className="signin-input" type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Enter your password" required />
          </label>
          <br/>
          <br/>
          <button type="submit" className="red-button">Sign in</button>
        </form>
        {message && <p>{message}</p>}
      </div>
    );
  };

  return (
    <div className="App">
      {isAuthenticated ? (
        <>
          <Header signOut={handleSignOut} />
          <main className="main-content-background">
            <ServiceProviderLinks />
          </main>
        </>
      ) : (
        <SignIn onSignIn={handleSignIn} />
      )}
    </div>
  );
}

export default App;